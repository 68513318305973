import React, { Component } from 'react';
import { HeaderWrapper } from './Header.styled';
import { Link, Toolbar, Typography } from '@mui/material';



interface HeaderProps {
   handleButtonClick: (component: string) => void;
   sections: ReadonlyArray<{
      title: string;
      url: string;
   }>;
   title: string
}

class Header extends Component<HeaderProps> {

   public static defaultProps = {
      title: '',
   };


   constructor(props: HeaderProps) {
      super(props);
   }


   render(): React.ReactNode {

      return (
         <HeaderWrapper data-testid="Header">

            <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
               <Typography
                  component="h2"
                  variant="h5"
                  color="inherit"
                  align="center"
                  noWrap
                  sx={{ flex: 1 }}
               >
                  {this.props.title}
               </Typography>
            </Toolbar>
            <Toolbar
               component="nav"
               variant="dense"
               sx={{ justifyContent: 'space-between', overflowX: 'auto' }}
            >
               {this.props.sections.map((section) => (
                  <Link
                     color="inherit"
                     noWrap
                     key={section.title}
                     variant="body2"
                     onClick={() => this.props.handleButtonClick(section.url)}
                     sx={{ p: 1, flexShrink: 0, cursor: 'pointer' }}
                  >
                     {section.title}
                  </Link>
               ))}
            </Toolbar>


         </HeaderWrapper>
      );

   }


}

export default Header;
