class SizeScreen {
    static width(): number {
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }
  
    static height(): number {
      return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }
  }
  
  export default SizeScreen;