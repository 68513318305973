import React, { Component, ReactNode } from 'react';
import { CopyrightWrapper } from './Copyright.styled';
import { Link, Typography } from '@mui/material';

interface CopyrightProps {
   title: string
}

class Copyright extends Component<CopyrightProps> {


   render(): ReactNode {
      return (
         <CopyrightWrapper data-testid="Copyright">
            <Typography variant="body2" color="text.secondary" align="center">
               {'Copyright © '}
               <Link color="inherit" href="/">
                  {this.props.title}
               </Link>{' '}
               {new Date().getFullYear()}
               {'.'}
            </Typography>
         </CopyrightWrapper>
      )
   }

}

export default Copyright;
