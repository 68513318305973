import React, { createContext, Component, ReactNode, SetStateAction, Dispatch } from 'react';

interface GlobalState {
  isSmallScreen: boolean;
}

interface AppContextProps {
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

const AppContext = createContext<AppContextProps>({} as AppContextProps);

interface AppProviderProps {
  children: ReactNode;
}

class AppProvider extends Component<AppProviderProps, GlobalState> {
  constructor(props: AppProviderProps) {
    super(props);
    this.state = {
      isSmallScreen: false,
    };
  }

  setGlobalState: AppContextProps['setGlobalState'] = (newState) => {
    this.setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  render() {
    const { children } = this.props;

    return (
      <AppContext.Provider
        value={{
          globalState: this.state,
          setGlobalState: this.setGlobalState,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}


export {AppContext, AppProvider}