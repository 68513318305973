import React, { ErrorInfo, ReactNode } from 'react';
import { Typography, Button } from '@mui/material';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, error, errorInfo });
    // Aqui você pode enviar o erro para algum serviço de rastreamento de erros (como Sentry, Bugsnag, etc.)
    // ou realizar qualquer outra ação desejada, como exibir uma mensagem de erro.
  }

  handleReloadPage = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // Você pode personalizar a aparência da mensagem de erro aqui
      return (
        <>
          <Typography variant="h4" color="error">
            Oops! Algo deu errado.
          </Typography>
          <Typography variant="h6">
            Faça o reload da página
          </Typography>
          <Button variant="contained" color="primary" onClick={this.handleReloadPage}>
            Tente novamente
          </Button>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
