import React, { Component } from 'react';
import { MainFeaturedPostWrapper } from './MainFeaturedPost.styled';
import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import { spacing } from '@mui/system';


interface MainFeaturedPostProps {
   post: {
      description: string;
      image: string;
      imageText: string;
      title: string;
   };
}

interface MainFeaturedPostState {
   isImageLoaded: boolean;
}

class MainFeaturedPost extends Component<MainFeaturedPostProps, MainFeaturedPostState> {
   public static defaultProps = {};

   constructor(props: MainFeaturedPostProps) {
      super(props);
      this.state = {
         isImageLoaded: false
      };
   }

   handleImageLoad = () => {
      this.setState({ isImageLoaded: true });
   };

   render(): React.ReactNode {
      const { isImageLoaded } = this.state;
      const { image, imageText, title, description } = this.props.post;

      return (
         <MainFeaturedPostWrapper data-testid="MainFeaturedPost">
            <Paper
               sx={{
                  position: 'relative',
                  backgroundColor: 'grey.800',
                  color: '#fff',
                  mb: 4,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundImage: `url(${image})`,
               }}
            >
               {/* Increase the priority of the hero background image */}
               {!isImageLoaded && <img style={{ display: 'none' }} src={image} alt={imageText} />}
               <Box
                  sx={{
                     position: 'absolute',
                     top: 0,
                     bottom: 0,
                     right: 0,
                     left: 0,
                     backgroundColor: 'rgba(0,0,0,.3)',
                  }}
               />
               <Grid container>
                  <Grid item md={6}>
                     <Box
                        sx={{
                           position: 'relative',
                           p: { xs: 3, md: 6 },
                           pr: { md: 0 },
                        }}
                     >
                        <Typography component="h1" variant="h3" color="inherit" gutterBottom sx={{ marginTop: 10, marginBottom: 10 }}>
                           {title}
                        </Typography>
                        <Typography variant="h5" color="inherit" paragraph>
                           {description}
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Paper>
         </MainFeaturedPostWrapper>
      );
   }
}

export default MainFeaturedPost;
