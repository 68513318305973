import React, { Component, ReactNode } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import SizeScreen from '../../commons/SizeScreen';
import { AppContext } from '../../AppProvider';

interface FeaturedPostProps {

   post: {
      date: string;
      description: string;
      image: string;
      imageLabel: string;
      title: string;
   };
}

interface FeaturedPostState {
   window: { innerWidth: number, innerHeight: number };
}

class FeaturedPost extends Component<FeaturedPostProps, FeaturedPostState> {

   static contextType = AppContext;
   context!: React.ContextType<typeof AppContext>;


   constructor(props: FeaturedPostProps) {
      super(props);
      this.state = {
         window: { innerWidth: 0, innerHeight: 0 }
      }
   }

   componentDidMount() {
      window.addEventListener('resize', this.handleResize);
   }

   componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
   }

   handleResize = () => {
      this.setState({ window: { innerWidth: SizeScreen.width(), innerHeight: SizeScreen.height() } });
   };

   render(): ReactNode {
      const { post } = this.props;

      const { innerWidth } = this.state.window

      return (
         <Grid item xs={12} sm={12} md={6}>
            <CardActionArea component="a" href="#">
               <Card sx={{ display: 'flex' }}>
                  <CardContent sx={{ flex: 1 }}>
                     <Typography component="h2" variant="h5" minHeight={innerWidth < 970 && innerWidth >= 900 ? "70px" : "20"}>
                        {post.title}
                     </Typography>
                     <Typography variant="subtitle1" color="text.secondary">
                        {post.date}
                     </Typography>
                     <Typography variant="body2" color="text.secondary" paragraph minHeight="100px">
                        {post.description}
                     </Typography>

                  </CardContent>
                  <CardMedia
                     component="img"
                     sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
                     image={post.image}
                     alt={post.imageLabel}
                  />
               </Card>
            </CardActionArea>
         </Grid>

      )
   }
}

export default FeaturedPost;
