import React, { Component, FC, ReactNode } from 'react';
import { FooterWrapper } from './Footer.styled';
import { Box, Container, Link, Typography } from '@mui/material';
import Copyright from '../Copyright/Copyright';

interface FooterProps {
   description: string,
   title: string
}


class Footer extends Component<FooterProps> {

   public static defaultProps = {
   };


   constructor(props: FooterProps) {
      super(props);
   }


   render(): ReactNode {
      return (
         <FooterWrapper data-testid="Footer">
            <Box component="footer" sx={{ py: 6 }}>
               <Container maxWidth="lg">
                  <Typography variant="h6" align="center" gutterBottom>
                     {this.props.title}
                  </Typography>
                  <Typography
                     variant="subtitle1"
                     align="center"
                     color="text.secondary"
                     component="p"
                  >
                     {this.props.description}
                  </Typography>
                  <Copyright title={this.props.title} />
               </Container>
            </Box>
         </FooterWrapper>
      )
   }
}

export default Footer;
