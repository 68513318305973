import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { AppProvider } from './AppProvider';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import ErrorBoundary from './ErrorBoundary';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY ?? '';

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <AppProvider>
      <ErrorBoundary>
        <GoogleReCaptchaProvider useRecaptchaNet={true}
          reCaptchaKey={reCaptchaKey}
          language='pt-BR'

          scriptProps={{ async: true, appendTo: "body" }}
        >
          <App />
        </GoogleReCaptchaProvider>
      </ErrorBoundary>
    </AppProvider>

  </ThemeProvider>,
);
