import React, { Component, ReactNode } from 'react';
import { HomePageWrapper } from './HomePage.styled';
import MainFeaturedPost from '../MainFeaturedPost/MainFeaturedPost';
import { Grid } from '@mui/material';
import FeaturedPost from '../FeaturedPost/FeaturedPost';

const featuredPosts = [
   {
      title: 'Prepare-se para o futuro',
      date: '',
      description:
         'Conecte-se ao futuro com nossa expertise em cloud computing e transforme sua empresa em uma líder digital.',
      image: `${process.env.PUBLIC_URL}/imagens/consultoria-em-cloud-computing.webp`,
      imageLabel: 'Cloud Computing',
   },
   {
      title: 'Inovação',
      date: '',
      description:
         'Desperte o poder da inovação com soluções em nuvem sob medida para o seu negócio.',
      image: `${process.env.PUBLIC_URL}/imagens/cloud-computing.webp`,
      imageLabel: 'Cloud Computing',
   },

];

interface HomePageProps {
}


class HomePage extends Component<HomePageProps> {


   public static defaultProps = {
   };

   constructor(props: {}) {
      super(props);
   }


   render(): ReactNode {

      return (
         <HomePageWrapper data-testid="HomePage">


            <MainFeaturedPost post={{
               
               title: 'ITThink Consulting',
               description: "Desbloqueie o potencial ilimitado da sua empresa com soluções de TI inovadoras com expertise em cloud computing.",
               image: `${process.env.PUBLIC_URL}/marvin-meyer-SYTO3xs06fU-unsplash.webp`,
               imageText: 'Pensando como TI'
            }} />

            <Grid container spacing={1}>
               {featuredPosts.map((post) => (
                  <FeaturedPost key={post.title} post={post} />
               ))}
            </Grid>

         </HomePageWrapper>
      )
   }
}

export default HomePage;

