import React, { Component, ReactNode, Suspense } from 'react';
import Header from './components/Header/Header';
import { Container } from '@mui/material';
import Footer from './components/Footer/Footer';
import AboutPage from './components/AboutPage/AboutPage.lazy';
import ContactPage from './components/ContactPage/ContactPage.lazy';
import ServicePage from './components/ServicePage/ServicePage.lazy';
import HomePage from './components/HomePage/HomePage';

const containerStyle = {
  minHeight: '1000px', // Defina aqui a altura mínima desejada
};

interface AppState {
  currentComponent: string;
}

export default class App extends Component<{}, AppState> {

  public static defaultProps = {
  };

  constructor(props: {}) {

    super(props);
    this.state = {
      currentComponent: 'home'
    };
    this.handleVerify = this.handleVerify.bind(this);
  }

  handleButtonClick = (component: string) => {
    this.setState({ currentComponent: component });
  };

  handleVerify(token: string) {
    console.log(token);
  }

  render(): ReactNode {
    const { currentComponent } = this.state;

    let componentToRender;
    switch (currentComponent) {
      case 'home':
        componentToRender = <HomePage />;
        break;
      case 'services':
        componentToRender = <ServicePage />;
        break;
      case 'about':
        componentToRender = <AboutPage />;
        break;
      case 'contact':
        componentToRender = <ContactPage key={Math.random()} />
        break;
      default:
        componentToRender = <HomePage />;
    }

    return (
      <div>
        <Container maxWidth="lg" style={containerStyle}>
          <Header handleButtonClick={this.handleButtonClick} title='' sections={[
            { title: 'Início', url: 'home' },
            { title: 'Serviços', url: 'services' },
            { title: 'Sobre', url: 'about' },
            { title: 'Contato', url: 'contact' }
          ]} />

          <main>
            {componentToRender}
          </main>
          <Footer title='ITThink Consulting' description='contato@itthink.com.br' />
        </Container>
      </div>
    );
  }

}
